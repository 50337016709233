<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang='less'>
#app {
  height: 100vh;
}

html,
body {
  margin: 0;
  padding: 0;
}

.form-select-card {
  margin-bottom: 20px;

  .el-card__body {
    padding-bottom: 0;
  }
}
</style>
