// mock数据模拟
import Mock, { Random } from 'mockjs'

//资料数据
let List = []
for (let i = 0; i < 10; i++) {
    List.push(
        Mock.mock({
            "id": i + 1001,
            "school|1": ['中山大学', '华南理工大学', '暨南大学', '深圳大学', '深圳大学'],
            "academy|1": ['计算机与软件学院', '电子与信息工程学院', '数学科学学院', '电子与信息工程学院', '机电与控制工程学院'],
            "speciality|1": ['软件工程系', '人工智能系', '软件工程系', '人工智能系', '计算机科学与技术系'],
            "subject": 'C语言与程序设计',
            "image": Random.image('100x100', '#894FC4', '#FFF', 'png', '@id'),
            // "information":'@/基于MVC的试卷生成及主观题判卷算法研究.pdf'
        })
    )

}
export default {
    //获取资料列表
    getInformationList: () => {
        return {
            code: 200,
            data: {
                total: List.length,
                informationList: List
            }
        }
    },

    //新增
    addInformation: (options) => {
        let informationData = JSON.parse(options.body).params.informationData
        let id = List.length + 1001
        informationData.id = id
        //检验科目是否存在
        let val = List.find(a => a.subject === informationData.subject)
        if (val) {
            return {
                code: -200,
                message: '添加失败,科目已存在'
            }
        } else {
            List = List.concat(informationData)
            return {
                code: 200,
                message: '添加操作成功'
            }
        }
    },
    //修改
    editInformation: (options) => {
        let informationData = JSON.parse(options.body).params.informationData
        console.log(informationData)
        List = List.map(val => {
            return val.id === informationData.id ? informationData : val;
        });
        return {
            code: 200,
            message: '编辑操作成功'
        }
    },
    // 查询
    searchInformation: (options) => {
        let keywords = JSON.parse(options.body).params.keywords
        let searchList = []
        searchList = List.filter((a) => {
            return a.school.indexOf(keywords) !== -1 || a.academy.indexOf(keywords) !== -1 || a.speciality.indexOf(keywords) !== -1 || a.subject.indexOf(keywords) !== -1
        })
        let len = searchList.length
        if (len > 0) {
            return {
                code: 200,
                message: '查询成功',
                data: {
                    total: len,
                    searchList
                }
            }
        } else {
            return {
                code: -200,
                message: '无相关关键字,查询失败'
            }
        }
    },
    //删除
    deleteInformation: (options) => {
        let id = parseInt(JSON.parse(options.body).params.id)
        if (!id) {
            return {
                code: -200,
                message: '参数不正确'
            }
        } else {
            List = List.filter(a => a.id !== id)
            return {
                code: 200,
                message: '学校资料信息'+id+'删除成功'
            }
        }
    },

}