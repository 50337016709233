import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import router from './router'
import store from './store'
import axios from 'axios'
import './mock/mock.js'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$axios = axios

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    next();
  } else {
    // token失效的主动处理
    // 获取一下记录token的时间
    let start = new Date(localStorage.getItem("timestamp")).getTime();
    // 获取一下当前时间
    let now = Date.now();
    // console.log('now', now, start)
    // 算出时间差
    let hour = parseInt((now - start) / (1000 * 60 * 60))
    // console.log(hour)
    // 判断是否超过2小时
    if (hour >= 2) {
      // 代表token过期
      // await store.dispatch('user/logout')
      // alert('登录已过期,请重新登录');
      next("/login");
      window.localStorage.clear();
      // return代表不往下执行，所以这个请求不会发送
      return
    } else {
      next()
    }
  }
});

new Vue({
  router,
  store,
  axios,
  render: h => h(App),
}).$mount('#app')
