import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/SchoolManagement'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/frame',
        name: 'Frame',
        component: () => import('../components/Frame.vue'),
        redirect: '/home',
        children: [
            {
                path: '/AcademyManagement',
                name: 'AcademyManagement',
                component: () => import('../views/AcademyManagement/AcademyManagement.vue')
            },
            {
                path: '/CourseManagement',
                name: 'CourseManagement',
                component: () => import('../views/CourseManagement/CourseManagement.vue')
            },
            {
                path: '/CourseInformation',
                name: 'CourseInformation',
                component: () => import('../views/CourseInformation/Upload.vue')
            },
            {
                path: '/CheckManagement',
                name: 'CheckManagement',
                component: () => import('../views/CheckManagement/CheckManagement.vue')
            },
            {
                path: '/SchoolManagement',
                name: 'SchoolManagement',
                component: () => import('../views/SchoolManagement/School.vue')
            },
            {
                path: '/PostgraduateSubject',
                name: 'PostgraduateSubject',
                component: () => import('../views/PostgraduateManagement/PostgraduateSubject.vue')
            },
            {
                path: '/PostgraduateManagement',
                name: 'PostgraduateManagement',
                component: () => import('../views/PostgraduateManagement/Postgraduate.vue')
            },
            {
                path: '/ProductManagerment',
                name: 'ProductManagerment',
                component: () => import('../views/ProductManagerment/ProductManagerment.vue')
            },
            {
                path: '/UserManagement',
                name: 'UserManagement',
                component: () => import('../views/UserManagement/UserManagement.vue')
            },
            {
                path: '/OrderManagement',
                name: 'OrderManagement',
                component: () => import('../views/OrderManagement/OrderManagement.vue')
            },
            {
                path: '/RefundManagement',
                name: 'RefundManagement',
                component: () => import('../views/RefundManagement/RefundManagement.vue')
            },
            {
                path: '/BillManagement',
                name: 'BillManagement',
                component: () => import('../views/BillManagement/BillManagement.vue')
            },
            {
                path: '/sysHisLogin',
                name: 'SysHisLogin',
                component: () => import('../views/SysHisLogin.vue')
            },


            {
                path: '/Personal',
                name: 'Personal',
                component: () => import('../views/Personal/Personal.vue')
            },
            {
                path: '/CourseInformation',
                name: 'CourseInformation',
                component: () => import('../views/CourseInformation/Information.vue')
            },
            {
                path: '/NewsManagement',
                name: 'NewsManagement',
                component: () => import('../views/NewsManagement/Newsmanage.vue')
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',//开启history模式需要服务端支持
    // base: '/admin',//配置nginx访问结构
    routes
})

//避免重复点击导航爆错 
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}




export default router